import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Markdown from 'markdown-to-jsx'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHero from '../components/hero/page'
import Contact from '../components/contact'
import FullForm from '../components/forms/full'

import styles from '../styles/page.module.scss'

const ContactUsPage = () => (
  <StaticQuery
    query={graphql`
      query ContactUsQuery {
        currentPage: allContentYaml(filter: { url: { eq:"/contact-us" } }) {
          edges {
            node {
              title
              content
              description
              heroLeadLine
              heroLine
              heroPoints
              infoCardsTitle
              infoCards {
                cardTitle
                cardImage
                cardDescription
              }
            }
          }
        }
      }
    `}
    render={data => {
      const page = data.currentPage.edges[0].node

      return (
        <Layout>
          <SEO
            title={ page.title }
            description={ page.description }
            canonical="https://motorinjuryhelpline.com.au/contact-us"
          />
          <PageHero
            leadLine={ page.heroLeadLine }
            heroLine={ page.heroLine }
            heroPoints={ page.heroPoints }
            disableContact
          />
          <div className={ styles.content }>
            <div className={ styles.content__container }>
              <Markdown
                children={ page.content }
              />
              <FullForm />
            </div>
          </div>
          <Contact
            optionalTitle="How else can I contact Motor Injury Helpline?"
          />
        </Layout>
      )
    }
    }
  />
)

export default ContactUsPage
