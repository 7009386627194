import React from 'react'

import styles from './fform.module.scss'

const FullForm = () => (
  <div className={ styles.fullForm }>
    <form
      name="full-contact-us-form"
      method="POST"
      action="/thank-you"
      data-netlify="true"
      netlify-honeypot="nofill"
    >
      <input
        type="hidden"
        name="form-name"
        value="full-contact-us-form"
      />
      <input
        name="Full Name"
        type="text"
        placeholder="Full Name"
        required
      />
      <div>
        <input
          name="E-Mail"
          type="text"
          placeholder="E-Mail Address"
          required
        />
        <input
          name="Phone Number"
          type="num"
          placeholder="Phone Number"
          required
        />
      </div>
      <textarea
        name="Message"
        placeholder="Type your message here (500 characters maximum)."
        maxLength="500"
        required
      />
      <input
        type="text"
        placeholder="Don't fill this out if you're a human!"
        name="nofill"
        style={{
          position: 'absolute',
          top: '-9000px',
          left: '-9000px',
        }}
      />
      <button type="submit">Send Message</button>
    </form>
  </div>
)

export default FullForm
